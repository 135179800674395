import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CFormSelect,
  CFormTextarea

} from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import axios from "axios";
import { formatUTC } from 'src/store';
import dayjs from 'dayjs';
import AddHeaderSlider from 'src/components/headers/AddHeaderSlider';
import AxiosInstance from 'src/components/Axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import PageInfoList from 'src/components/page-info/PageInfoList';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

import FormAttributes from 'src/components/attributes/FormAttributes';
import AddCategories from 'src/components/category/AddCategories';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";
import MediaFrom from 'src/components/media/MediaFrom';
import GalleryObject from 'src/components/gallery/GalleryObject';
import DatePicker from "react-datepicker";

const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}` } };

export async function LoaderObjectsUpdate({ params }) {

  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get('/objects/' + params.id).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}
const ObjectsForm = ({user_data}) => {
  if(!Permissions(user_data, 'Objects', 'EDIT_OBJECTS')){
      return <PermissionsNoPage />;
  }
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');

  const [latitude, setLatitude] = useState((data != undefined) ? data.latitude : '');
  const [longitude, setLongitude] = useState((data != undefined) ? data.longitude : '');
  const [modelType, setModelType] = useState((data != undefined) ? data.modelType : '');
  const [models, setModels] = useState((data != undefined) ? data.models : '');

  const [status, setStatus] = useState((data != undefined) ? data.status : '');

  const [sliders, setSliders] = useState((data != undefined && data.slider != undefined)? data.slider.id: false);
  const [dateEntered, setDateEntered] = useState((data != undefined) ? new Date(data.dateEntered).toISOString() : new Date().toISOString());

  const [currentMedia, setCurrentMedia] = useState((data != undefined) && data.mainMedia);
  const popup = MyToastify();

  const editorRef = useRef(null);
  const navigate = useNavigate();
  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {

      const article = {
        name: name,
        latitude: latitude,
        longitude: longitude,
        modelType: modelType,
        models: models,
        status: status,
        dateEntered: dateEntered,
        content: editorRef.current.getContent(),
      };

      if (data != undefined) {
        axios.put(process.env.REACT_APP_SERVER_URL + '/api/objects/' + data.id, article, header).then(()=>{
          popup.toastifySuccess('Загальні дані оновлено');
        });
      } else {
        axios.post(process.env.REACT_APP_SERVER_URL + '/api/objects', article, header).then((response)=>{
          navigate(`/objects/update/${response.data.id}`);
        });
      }
    }
  }

  // useEffect(() => {
  //   if(sliders != undefined){
  //     const response = AxiosInstance.put(`projects/${data.id}`, {slider:`/api/sliders/${sliders}`}).then(()=>{
  //       popup.toastifySuccess('Слайдер додано');
  //     });
  //   }
  // }, [sliders]);

  function changeMedia(){
    if(currentMedia && data.mainMedia !== null) {
      const oldId = data.mainMedia.id;
      AxiosInstance.put(`/objects/${data.id}`, {mainMedia: currentMedia['@id']}).then(() => {
        popup.toastifySuccess('Зображення оновлено');
        AxiosInstance.delete(`/media_objects/${oldId}`); // видалення старої прив'язки
      })
    }
    else {
      AxiosInstance.put(`/objects/${data.id}`, {mainMedia: currentMedia['@id']}).then(() => {
          popup.toastifySuccess('Зображення оновлено');
      })
    }
  }

  const datePicker = (value) => {
    let formattedDate = value.toISOString();
    setDateEntered(formattedDate);
  }

  return (
    <>
      <ToastContainer />
      <h2>{name}</h2>
      <hr/>
      <Tab.Container id="left-tabs-example" defaultActiveKey="info">
        <Row>
          <Col sm={2}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="info">Загальні</Nav.Link>
              </Nav.Item>
            {data !== undefined && <>
              <Nav.Item>
                <Nav.Link eventKey="img">Зображення</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="gallery">Галерея</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="attributes">Атрибути</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="category">Категорії</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="data">Додаткові дані</Nav.Link>
              </Nav.Item>
            </>}
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <CForm onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
                      <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
                    </div>
                    <div className="row mb-3">
                      <div className="col">
                        <CFormLabel htmlFor="exampleFormControlInput1">Довгота</CFormLabel>
                        <CFormInput type="number" id="exampleFormControlInput1" value={longitude} onChange={(e) => setLongitude(e.target.value)} autoComplete="off" />
                      </div>
                      <div className="col">
                        <CFormLabel htmlFor="exampleFormControlInput1">Широта</CFormLabel>
                        <CFormInput type="number" id="exampleFormControlInput1" value={latitude} onChange={(e) => setLatitude(e.target.value)} autoComplete="off" />
                      </div>

                    </div>
                    <div className="mb-3">
                        <CFormLabel htmlFor="exampleFormControlInput1">Дата створення</CFormLabel>
                        <DatePicker
                          className="form-control"
                          dateFormat="yyyy-MM-dd"
                          selected={new Date(dateEntered)}
                          onChange={(date) => datePicker(date)}
                        />

                    </div>
                    <div className="mb-3">
                      <CFormLabel htmlFor="exampleFormControlInput1">Модель</CFormLabel>
                      <CFormTextarea type="text" id="exampleFormControlInput1" value={models} onChange={(e) => setModels(e.target.value)} autoComplete="off" />
                    </div>
                    <div className="mb-3">
                      {/* <CFormLabel htmlFor="exampleFormControlInput1">Тип моделі</CFormLabel> */}
                      <CFormSelect
                        aria-label="Default select example"
                        onChange={(e) => setModelType(e.target.value)}
                        defaultValue={modelType}
                        required
                        options={[
                          { label: 'Тип моделі', value: '' },
                          { label: 'sketchfab', value: 'Sketchfab' },
                          { label: 'nira', value: 'Nira' },
                          { label: 'other', value: 'інше' }
                        ]}
                      />
                    </div>
                    <div className="mb-3">
                      {/* <CFormLabel htmlFor="exampleFormControlInput1">Статус</CFormLabel> */}
                      <CFormSelect
                        aria-label="Default select example"
                        onChange={(e) => setStatus(e.target.value)}
                        defaultValue={status}
                        required
                        options={[
                          { label: 'Статус', value: '' },
                          { label: 'Completed', value: 'Completed' },
                          { label: 'Planned', value: 'Planned' },
                          { label: 'Pending', value: 'Pending' }
                        ]}
                      />
                    </div>
                    <div className="mb-3">
                      <Editor
                        apiKey={apiKey}
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={(data != undefined) ? data.content : ''}
                        init={{
                          height: 300,
                          menubar: 'edit view insert format',
                          plugins: [
                            'advlist','autolink',
                            'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks','fullscreen',
                            'insertdatetime','media','table', 'help', 'code'
                         ],
                          toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
                            'alignleft aligncenter alignright alignjustify | ' +
                            'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
                          content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
                          font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                        }}
                      />
                    </div>
                    <CButton type="submit" color="success">Зберегти</CButton>
                </CForm>
              </Tab.Pane>
            {data !== undefined && 
            <>
              <Tab.Pane eventKey="img">
                <MediaFrom type="materials" setNewMedia={setCurrentMedia} toastifySuccess={popup.toastifySuccess} />
                {currentMedia && <>
                  <img className='object-img' width={300} height={300} src={process.env.REACT_APP_SERVER_URL + currentMedia.contentUrl} />
                  <CButton type="submit" onClick={changeMedia} className="my-3" color="success">Зберегти Зображення</CButton> </>}
                </Tab.Pane>
                <Tab.Pane eventKey="gallery">
                  <GalleryObject dataId={data.id} popup={popup}  currentGalleries={data.galleries}/>
                </Tab.Pane>
              <Tab.Pane eventKey="attributes">
                <FormAttributes dataId={data.id} attributeItems={data.attributeItems} toastifySuccess={popup.toastifySuccess} type="products" url="/objects" /> 
              </Tab.Pane>
              <Tab.Pane eventKey="category">
                <AddCategories type="objects" data_id={data.id} toastifySuccess={popup.toastifySuccess} /> 
              </Tab.Pane>
              <Tab.Pane eventKey="data">
                  <PageInfoList id={data.id} type="object" />
              </Tab.Pane>
            </>}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      {/* <AddHeaderSlider id={(sliders !== false) ? sliders : undefined} newSlider={setSliders} parentUrl={`objects/${data.id}`}/>   */}
      {/* {(data != undefined)&&
        <PageInfoList id={data.id} type="project" />
      } */}
    </>
  );
};

export default ObjectsForm;
